import React from "react";

import TopNav from "../TopNav";
import Footer from "../Footer";
import ThankYouGraphic from "../../../static/image/thank-you-graphic.png";
import InstagramLogo from "../../../static/icon/Instagram.svg";
import FacebookLogo from "../../../static/icon/Facebook.svg";
import YoutubeLogo from "../../../static/icon/Youtube.svg";
import { StaticImage } from "gatsby-plugin-image";

const ThankYou: React.FC = () => {
  return (
    <>
      <StaticImage
        src={"../../../static/image/deeple-store-management-bg.jpg"}
        alt="Header background"
        loading="eager"
        placeholder="blurred"
        className="absolute header-pink-home"
      />

      <div className="center-container">
        <TopNav isWhiteTheme />
      </div>

      <div className="header-container ">
        <div className="center-container mb-24 md:mb-0 mt-8 w-5/6 lg:3/5 xl:w-4/6 relative max-w-[720px]">
          <div className="grid grid-cols-1">
            <div className="pb-16 text-center ">
              <h1 className="text-2xl md:text-4xl text-black mb-8">
                ขอบคุณที่ลงทะเบียนกับเรา
              </h1>

              <p className="text-black text-sm md:text-lg">
                ทีมงาน deeple จะติดต่อกลับหาคุณ
              </p>
              <p className="text-black text-sm md:text-lg">
                ช่วงเวลาทำการ (จันทร์ - ศุกร์ 10.00 น. - 18.00 น.)
              </p>
            </div>

            <div
              className="grid bg-white rounded-lg px-8 md:px-12 py-8 pb-12 justify-center gap-y-4"
              style={{
                boxShadow: "-11px 3px 20px 6px rgb(204 216 255 / 20%)",
              }}
            >
              <div>
                <img className="mx-auto" src={ThankYouGraphic} />
              </div>

              <div className="text-center text-lg text-gray-400 pb-4">
                รู้จัก deeple เพิ่มเติม ผ่านช่องทาง Social Media
              </div>

              <div className="flex justify-around ">
                <a
                  className="cursor-pointer"
                  href="https://www.instagram.com/deeplethailand/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="w-8" src={InstagramLogo} alt="Instagram" />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.facebook.com/deeplethailand"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="w-8" src={FacebookLogo} alt="Facebook" />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.youtube.com/deeplethailand"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="w-8" src={YoutubeLogo} alt="Youtube" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </>
  );
};
export default ThankYou;
